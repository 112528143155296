<template>
    <div class="bg-white">
        <section v-show="id_pedido === null">
            <div class="row h-100 my-5 mx-0 align-items-center justify-content-center">
                <div class="text-center f-15 f-500 d-middle-center flex-column" style="width:225px;">
                    <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                        <img height="80" src="/img/no-imagen/click.svg" alt="" />
                    </div>
                    <p>Selecciona uno de los Pedidos activos del panel de la izquierda.</p>
                </div>
            </div>
        </section>
        <section v-show="id_pedido" class="h-100">
            <div class="custom-scroll overflow-auto" :style="`height:calc(100vh - ${ $route.name == 'admin.almacen.productos.estado' || $route.name == 'admin.almacen.productos.desconocidos' || $route.name == 'admin.almacen.productos.pedidos' ? 232 : 240}px);`">
                <div v-if="pedido.id_pedido != null" class="border-bottom text-general">
                    <div class="row mx-0 my-2 align-items-center">
                        <div class="col-auto px-3" />
                        <p class="col f-600 f-17 text-general">{{ $config.cliente }}</p>
                    </div>
                    <div class="row mx-0 align-items-center my-2 px-2">
                        <img class="border rounded-circle bg-whitesmoke" :src="pedido.datos_pedido.avatar ? pedido.datos_pedido.avatar : '/img/no-imagen/sin_user.png'" width="34" height="34" alt="" />
                        <p class="mx-2 text-general f-15">{{ pedido.datos_pedido.nombre }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <div class="text-center" style="width:34px;">
                            <el-tooltip placement="bottom" content="Fecha Entrega" effect="light">
                                <i class="icon-calendar-clock text-general f-20" />
                            </el-tooltip>
                        </div>
                        <p class="col f-15 pl-2">{{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm a') }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <div class="text-center" style="width:34px;">
                            <el-tooltip placement="bottom" content="Dirección" effect="light">
                                <i class="icon-map-marker-outline text-general f-20 text-general" />
                            </el-tooltip>
                        </div>
                        <p class="col pl-2 f-15" v-html="formatearTextoHtml(pedido.datos_pedido.direccion)" />
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <div class="text-center" style="width:34px;">
                            <el-tooltip placement="bottom" content="Cobertura" effect="light">
                                <i class="icon-arrow-top-right text-general f-20" />
                            </el-tooltip>
                        </div>
                        <p class="col pl-2 f-15">{{ pedido.distancia }} Mts Aprox.</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <div class="text-center" style="width:34px;">
                            <i class="icon-phone text-general f-20" />
                        </div>
                        <p class="col pl-2 f-15">{{ pedido.datos_pedido.telefono || 'No registra' }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <div class="text-center" style="width:34px;">
                            <i class="icon-identification f-16 text-general" />
                        </div>
                        <p class="col pl-2 f-15">{{ pedido.datos_pedido.identificacion || 'No registra' }}</p>
                    </div>

                </div>
                <div v-if="pedido.id_pedido == null" class="border-bottom">
                    <div class="row mx-auto text-general f-14 justify-center my-2">
                        <span class="f-500">{{ $config.cliente }} del {{ $config.vendedor }}</span>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <i class="icon-cliente text-general f-20 mr-2" />
                        <p class="col f-15 text-general">{{ pedido.digitado_cliente_nombre || 'No registra' }}</p>
                        <div class="br-20 px-2 mx-2 bg-light-f5 text-general2">
                            <span class="mx-2">0</span>
                            <i class="f-20 icon-pedidos" />
                        </div>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center">
                        <i class="icon-phone text-general f-20 mr-2" />
                        <p class="col f-15 text-general">{{ pedido.digitado_cliente_telefono || 'No registra' }}</p>
                    </div>
                </div>
                <div class="border-bottom p-2">
                    <div class="row mx-0 align-items-center">
                        <div class="col-auto pl-0 pr-2">
                            <i class="icon-storefront f-22 text-general" />
                        </div>
                        <div class="col pl-0 text-general f-17 f-500">
                            Atiende
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center my-2">
                        <div class="col-auto px-3 mr-1" />
                        <img class="border br-5 bg-whitesmoke" :src="cliente.logo_mini ? cliente.logo_mini : '/img/no-imagen/sin_user.png'" width="46" height="46" />
                        <div class="col pl-2">
                            <p class="f-15 f-500 text-general">{{ cliente.nombre }}</p>
                        </div>
                        <div class="br-20 px-2 mx-2 bg-light-f5 text-general">
                            <i class="f-20 icon-pedidos" />
                            <span class="mx-2">{{ cantidad }}</span>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 text-general">
                        <el-tooltip placement="bottom" content="Dirección" effect="light">
                            <div class="col-auto pl-0 pr-2">
                                <i class="icon-map-marker-outline text-general f-22" />
                            </div>
                        </el-tooltip>
                        <div class="col pl-0 pt-1">
                            <p class="text-general f-17 f-500">Dirección de entrega</p>
                            <p class="f-15 my-1" v-html="formatearTextoHtml(cliente.direccion)" />
                            <p class="f-15 mt-2">{{ pedido.distancia }} Mts</p>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 align-items-center text-general">
                        <el-tooltip placement="bottom" content="Fecha Entrega" effect="light">
                            <div class="col-auto pl-0 pr-2">
                                <i class="icon-calendar-clock text-general f-22" />
                            </div>
                        </el-tooltip>
                        <p class="col px-0 f-15">{{ pedido.entrega_fecha | helper-fecha('ddd DD MMMM') }}</p>
                    </div>

                    <div class="row mx-0 mt-2 align-items-center">
                        <i class="icon-creditos f-15 text-general" />
                        <div class="col text-general mr-2 f-500">
                            Crédito
                        </div>
                        <div class="col-12 px-0">
                            <div class="row mx-0 align-items-center">
                                <div class="col-auto mr-2" />
                                <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                                    <i class="icon-clock text-general f-18" />
                                </el-tooltip>
                                <span class="f-14 mr-2 text-general">{{ convertMoney(pedido.saldo_vigente,pedido.idm_moneda) }}</span>

                                <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                                    <i class="icon-attention-alt text-general f-18" />
                                </el-tooltip>
                                <span class="f-14 text-general">{{ convertMoney(pedido.saldo_vencido,pedido.idm_moneda) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- linea de tiempo -->
                <div class="border-bottom py-2">
                    <div class="row mx-0 justify-center">
                        <div v-for="(act, index) in historial" :key="index" class="d-middle" style="width:55px;">
                            <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:44px;height:44px;padding:1px">
                                <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                    <i :class="`f-20 ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                                </div>
                            </div>
                            <div v-show="index != historial.length-1" class="w-20" :class="act.activo?'bg-general3':'bg-gris'" style="height:3px;" />
                        </div>
                    </div>
                    <p class="text-general f-500 f-15 text-center my-2 mt-3">
                        {{ nombreEstado }}
                    </p>
                    <template v-if="showTimeLine">
                        <div v-for="(act, index) in historial" :key="index" class="row mx-0 my-2">
                            <div class="col-auto">
                                <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:20px;height:20px;padding:1px">
                                    <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                        <i v-if="act.activo" :class="`f-20 icon-ok-circled ${act.activo?'text-general':'text-gris'}`" />
                                    </div>
                                </div>
                                <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-general3':'bg-gris'" style="width:2px;" />
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div class="col px-1">
                                        <p :class="act.activo?'text-general':'text-gris'">{{ act.content }}</p>
                                        <p class="f-14">{{ act.hora | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <p class="text-general f-14 cr-pointer text-center" @click="showTimeLine = !showTimeLine">
                        {{ showTimeLine ? 'Ver menos' : 'Ver más' }}  <i :class="`${showTimeLine ? 'icon-angle-up' : 'icon-angle-down'} f-20 text-general`" />
                    </p>
                </div>
                <!-- Mas informacion -->
                <div class="border-bottom py-2">
                    <div class="row mx-0 px-2">
                        <div class="col-auto pl-0 pr-2">
                            <i class="icon-pedidos f-20 text-general" />
                        </div>
                        <div class="col pl-0 my-auto text-general f-17 f-500">
                            Más Información
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto" />
                        <div class="col">
                            <div class="row mx-0 f-16">
                                <p class="col text-general f-15 px-0">
                                    No. Del pedido
                                </p>
                                <p class="col text-general f-15 text-right">
                                    {{ pedido.id }}
                                </p>
                            </div>
                            <p class="text-general f-500 f-15 mt-3">
                                Instrucciones
                            </p>
                            <p class="text-general f-15">{{ pedido.instrucciones ? pedido.instrucciones : 'Sin instrucciones de entrega' }}</p>
                        </div>
                    </div>
                </div>
                <!-- Resumen -->
                <div class="border-bottom p-2 text-general">
                    <div class="row mx-0 f-500 f-20 align-items-center mb-3">
                        <div class="col-auto pl-0 pr-2">
                            <i class="icon-comision f-17 text-general" />
                        </div>
                        <p class="col pl-0 text-general f-17">Detalle de cobro</p>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto pr-3" />
                        <div class="col pl-0">
                            <div class="row mx-0 justify-content-between f-15">
                                <p>Costo de productos ({{ resumen.cantidad_productos }})</p>
                                <p class="f-500">{{ convertMoney(resumen.val_productos,pedido.idm_moneda) }}</p>
                            </div>
                            <div class="row mx-0 justify-content-between f-15">
                                <p>Descuento</p>
                                <p class="text-general f-500">
                                    {{ convertMoney(resumen.val_descuentos,pedido.idm_moneda) }}
                                </p>
                            </div>
                            <div v-if="resumen.id_cupon" class="row mx-0 justify-content-between f-15">
                                <div class="d-middle">
                                    <i class="icon-cupon pr-1" />
                                    <p>{{ resumen.codigo_cupon }}</p>
                                </div>
                                <b class="text-general ">
                                    {{ convertMoney(resumen.val_cupon, pedido.idm_moneda) }}
                                </b>
                            </div>
                            <div class="row mx-0 justify-content-between f-15">
                                <p>Alianza {{ $config.vendedor }}</p>
                                <b class="text-general f-500">
                                    {{ convertMoney(resumen.val_condicion,pedido.idm_moneda) }}
                                </b>
                            </div>
                            <div v-if="resumen.val_descuento_lista > 0" class="row mx-0 justify-content-between f-15">
                                <p>Descuento Lista</p>
                                <b class="text-general f-500">
                                    {{ convertMoney(resumen.val_descuento_lista,pedido.idm_moneda) }}
                                </b>
                            </div>
                            <div class="row mx-0 py-2 f-500 justify-content-between border-top f-15">
                                <p>Sub Total</p>
                                <p>{{ convertMoney(resumen.subtotal, pedido.idm_moneda) }}</p>
                            </div>
                            <div class="row mx-0 justify-content-between f-15">
                                <p>Impuestos</p>
                                <p class="text-general f-500">
                                    {{ convertMoney(resumen.val_impuestos, pedido.idm_moneda) }}
                                </p>
                            </div>
                            <div class="row mx-0 justify-content-between f-15">
                                <p>Costo de envío</p>
                                <p class="f-500">{{ convertMoney(resumen.val_domicilios, pedido.idm_moneda) }}</p>
                            </div>
                            <div class="row mx-0 mt-2 f-500 justify-content-between border-top pt-2 f-15">
                                <p>Total a pagar</p>
                                <p>{{ convertMoney(resumen.val_total,pedido.idm_moneda) }}</p>
                            </div>
                            <div v-show="resumen.ganancia > 0" class="row mx-0 my-2 ticket br-8 py-1 f-15">
                                <div class="col pl-1 px-0 text-general f-15">
                                    Ganancias {{ $config.vendedor }}
                                </div>
                                <div class="col-auto ml-auto total-value">
                                    {{ convertMoney(resumen.ganancia, pedido.idm_moneda) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="pedido.estado === 3 && pedido.pago_estado === 1 && $can('botones_abastecimiento_otorgar_credito')" class="row mx-0 px-2 mt-3">
                    <div class="col-12 px-0">
                        <div class="bg-general text-white cr-pointer br-12 py-2 text-center" @click="otorgarCredito()">
                            Otorgar Crédito
                        </div>
                    </div>
                </div>
                <div v-show="pedido.pago_estado === 2" class="row mx-0 mt-3 mb-3 px-1">
                    <div class="col-12 px-0">
                        <div class="bg-general text-white header-credito d-middle-center f-500 f-15">
                            Crédito
                        </div>
                        <div class="borders pt-3" style="min-height:150px;">
                            <p class="text-muted mb-2 f-15 f-500 text-center">
                                Cuotas establecidas
                            </p>
                            <div v-for="(cuota, c) in cuotas" :key="c" class="row mx-3" :class="colorTextoCredito(cuota)">
                                <p class="tres-puntos border-bottom border-top py-2 col">
                                    <i class="f-18" :class="iconCredito(cuota)" />
                                    <span class="f-15">
                                        {{ cuota.fecha | helper-fecha('DD/MMM/YYYY') }}
                                    </span>
                                </p>
                                <p class="tres-puntos border-bottom border-top py-2 col-auto f-500">
                                    {{ convertMoney(cuota.valor,pedido.idm_moneda) }}
                                </p>
                            </div>
                            <p class="text-muted my-2 f-15 f-500 text-center">
                                Historial de Pagos
                            </p>
                            <div v-for="(p, i) in pagos" :key="`key2-${i}`" class="row mx-3 border-bottom mb-3">
                                <p class="col f-14 tres-puntos">
                                    {{ p.created_at | helper-fecha('DD MMMM YYYY') }}
                                </p>
                                <div class="col-auto">
                                    <el-tooltip placement="bottom" :content="p.comentario" effect="light">
                                        <i class="icon-attention-alt f-18 text-general" />
                                    </el-tooltip>
                                </div>
                                <p class="col-12 f-14">
                                    {{ convertMoney(p.valor,pedido.idm_moneda) }}
                                </p>
                            </div>
                            <div v-if="$can('botones_abastecimiento_otorgar_credito')" class="br-12 bg-whitesmoke2 text-muted border text-center py-2 f-500 my-2 mx-3 cr-pointer" @click="$refs.modalCancelarCredito.toggle()">
                                Cancelar Crédito
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="pedido.estado != 1" class="border-bottom p-2">
                    <div class="row mx-0 align-items-center">
                        <div class="col-auto pl-0 pr-2">
                            <i class="icon-cedis f-20 text-general" />
                        </div>
                        <div class="col pl-0 text-general f-17 f-500">
                            Responsable del CEDIS
                        </div>
                    </div>
                    <div class="row mx-0 my-2 align-items-center">
                        <div class="col-auto px-3 mr-1" />
                        <img
                        class="border br-5 bg-whitesmoke"
                        :src="pedido.foto_responsable ? pedido.foto_responsable : '/img/no-imagen/sin_user.png'"
                        width="44"
                        height="44"
                        />
                        <p class="col pl-2 f-14 text-general">{{ pedido.responsable || 'Sin Asignar' }} <br /> <i class="f-12">Responsable</i> </p>
                    </div>
                </div>
            </div>
            <modalVerInstrucciones ref="modalVerInstrucciones" />
            <modalEditarPedido ref="modalEditarPedido" />
            <modalOtorgarCredito ref="OtorgarCredito" @credito="mostar_credito" />

            <modalSiNo
            ref="modalCancelarCredito"
            titulo="Cancelar Credito"
            mensaje="¿Desea deshacer los datos del crédito?"
            icon="close"
            @accion="cancelar_credito"
            />
            <modal-imprimir ref="imprimirRecibo" />
        </section>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-admin'
import PdfPedidos from '~/services/pdf/pdf'

export default {
    components: {
        modalVerInstrucciones: () => import('../pedidos/partials/modalVerInstrucciones'),
        modalEditarPedido: () => import('../pedidos/partials/modalEditarPedido'),
        modalOtorgarCredito: () => import('../pedidos/partials/modalOtorgarCredito'),
    },
    data(){
        return {
            showTimeLine: false
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            historial: 'pedidos/pedidos_admin/historial',
            resumen: 'pedidos/pedidos_admin/resumen',
            cuotas: 'pedidos/pedidos_admin/cuotas',
            pagos: 'pedidos/pedidos_admin/pagos',

        }),
        cantidad(){
            let cant = this.cliente.cant_pedidos
            if(cant === 0){
                return 0
            }else if(cant > 0 && cant <= 5){
                return '1 - 5'
            }
            else if(cant > 5 && cant <= 20){
                return '+5'
            }
            else if(cant > 20 && cant <= 100){
                return '+20'
            }
            else if(cant > 100){
                return '+100'
            }else{
                return 0
            }
        },
        nombreEstado(){
            if(!this.historial.length) return ''
            let filtered = this.historial.filter(el => el.activo)
            if(!filtered.length) return ''

            return filtered[filtered.length - 1]["content"] ?? '';
        }
    },
    mounted(){
        this.historial[0].content = `Un ${this.$config.vendedor} ha solicitado un pedido`;
    },
    methods: {
        verInstrucciones(){
            this.$refs.modalVerInstrucciones.toggle(this.pedido)
        },
        color_fondo(cant){
            if(cant === 0){
                return '#FF0000'
            }else if(cant > 0 && cant <= 5){
                return '#FCA105'
            }
            else if(cant > 5 && cant <= 20){
                return '#00C93C'
            }
            else if(cant > 20 && cant <= 100){
                return '#8701AE'
            }
            else if(cant > 100){
                return '#0028C9'
            }

        },
        tipoPedido(tipo){
            switch (tipo){
            case 1:
                return 'icon-receipt'
            case 2:
                return 'icon-clock-alert-outline'
            case 3:
                return 'icon-cancel-circled-outline'
            case 4:
                return 'icon-shopping'
            default:

            }
        },
        otorgarCredito(){
            this.$refs.OtorgarCredito.toggle();
        },
        async cancelar_credito(res){
            if(!res){
                this.$refs.modalCancelarCredito.toggle()
                return
            }
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                const {data} = await Pedidos.cancelar_credito(this.id_pedido)
                this.notificacion('Mensaje','Datos del credito borrados correctamente','success')
                this.pedido.pago_estado = 1
                this.$refs.modalCancelarCredito.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        mostar_credito(){
            this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
            this.pedido.pago_estado = 2
        },
        colorTextoCredito(cuota){
            if(cuota.valor_pendiente == 0){
                return 'text-primary'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'text-red'
            }
            return ''
        },
        iconCredito(cuota){

            if(cuota.valor_pendiente == 0){
                return 'icon-ok-circled-outline text-green'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'icon-attention-alt text-red'
            }

            return ''
        },
        async imprimir(){
            try {
                if (this.pedido.id_pedido == null){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                    return
                }
                this.$refs.imprimirRecibo.toggle(this.id_pedido, this.pedido.id_pedido);

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.header-credito{
    border-radius: 12px 12px 0 0px;
    height: 50px;
}
.borders{
    border-left: 1px solid #5D5D5D59;
    border-right: 1px solid #5D5D5D59;
    border-bottom: 1px solid #5D5D5D59;
    border-radius: 0px 0px 12px 12px;
}
.text-red{ color: #FF2525; }
.text-green{ color: #28D07B; }
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
</style>
